/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-compare-area-width: var(--content-wrapper-width);
    --product-compare-sidebar-width: 330px;
    --product-compare-sidebar-padding: 30px;
    --product-compare-item-count: 3.2;
    --product-compare-item-gap: 15px;
    --prouduct-compare-additional-gap: 18px;
    --product-compare-column-width: calc(
        300px + var(--product-compare-item-gap) * 2
    );
}

.ProductCompare {
    overflow: auto;
    padding-block-end: 15px;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    @include mobile {
        padding: 0;
        min-height: calc(
            100vh - var(--header-total-height) - var(--navigation-tabs-height)
        );
        display: flex;
        flex-direction: column;
    }

    &:has(.ProductCompare-LoginLink) {
        .ProductWishlistButton {
            padding-block-end: 50px;
        }
    }

    .ProductCardRow {
        min-width: fit-content;
    }

    .ProductTitle {
        border-top: 1px solid #e0e0e0;
    }

    
    &-LoginLink {
        color: #bb8376;
        text-decoration: underline;
        border-right: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        min-width: 230px;
        padding: 20px 10px 20px 10px;
        border-bottom: 1px solid #e0e0e0;
        flex: 0 0 auto;
        text-align: justify;
        word-break: break-all;

        &:hover {
            color: var(--primary-base-color);
        }

        @include mobile {
            font-size: 12px;
        }
    }

    &-Row {
        display: flex;
        align-items: stretch;
        margin-block-start: 20px;
        column-gap: 10px;

        @include mobile {
            flex: 0 0 auto;
            margin-block-start: 0;
        }
    }

    &-FirstColumn {
        flex: 0 0 auto;
        position: sticky;
        inset-inline-start: 0;
        background-color: $white;
        z-index: 10;

        @include mobile {
            width: 19rem;
            font-size: 12px;
        }
    }

    &-Item {
        max-width: var(--product-compare-column-width);
        width: 230px;

        &:last-child {
            padding-inline-end: 0;
        }

        @include mobile {
            padding: 0;
            width: 230px;
        }

        .ProductCompareItem {
            .ProductCompareItem-Actions {
                .AddToCart {
                    width: 100%;
                }
                
                .ProductCompareItem-AddToCartBtnWrapper {
                    .AddToCart {
                        width: 100%;

                        .CartIcon {
                            color: red;

                            &:hover {
                                color: red;
                            }
                        }
                    }
                }
            }
        }
    }

    .ProductWishlistButton-Button.Button {
        padding-block-end: 30px;
    }

    &-AttributeTable {
        min-width: fit-content;
    }

    &-Empty {
        padding-block-start: 16px;
        
        @include mobile {
            margin-inline: 16px;
        }
    }

    .ClearButton {
        flex: 1;
        display: flex;
        align-items: center;
        position: sticky;
        inset-inline-start: 0;
        max-width: calc(var(--product-compare-sidebar-width) + var(--product-compare-item-gap));

        @include mobile {
            display: none;
        }

        .Button {
            width: 100%;
            margin-inline-end: var(--product-compare-sidebar-padding);
            text-transform: capitalize;

            @include mobile {
                text-transform: capitalize;
            }
        }
    }

    .ProductPriceRow {
        min-width: fit-content;
        width: 100%;
        border-block-start: 1px solid var(--secondary-base-color);
        padding-block: 24px;
    }

    &-RowPrices {
        min-width: fit-content;
        width: 100%;
    }

    .PriceLabel {
        font-weight: 700;
        font-size: 14px;
        margin: 0;
    }

    .ProductPrice {
        // padding: 2px var(--product-compare-item-gap);
        // width: calc(var(--product-compare-column-width));
        border-right: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-block-end: 1px solid #e0e0e0;
        min-width: 230px;
        padding-inline: 10px;
        display: flex;
        align-items: center;

        @include mobile {
            width: 230px;
        }

        &:last-child {
            padding-inline-end: 0;
        }
    }

    &-Scroller {
        inset-inline-start: 0;
        position: sticky;

        &Inner {
            margin: auto;
        }

        &Scroll {
            height: 20px;
            margin-inline-start: var(--product-compare-sidebar-width);
            overflow-x: auto;

            &::-webkit-scrollbar-thumb {
                background-color: var(--primary-base-color);
                height: 5px;
            }

            &::-webkit-scrollbar {
                background: rgba(0, 0, 0, 0.08);
                height: 5px;
            }

            @include mobile {
                margin-inline-start: 0;
            }
        }

        &Content {
            height: 1px;
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        h1 {
            font-size: 21px;

            @include mobile {
                font-size: 18px;
            }
        }

        @include mobile {
            padding: 16px;
        }
    }

    .AttributeTable {
        .ProductCompareAttributeRow {
            @include mobile {
                display: flex;
            }
        }
    }
}
