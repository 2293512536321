/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-compare-item-option-color: #adadad;
    --product-compare-item-divider-color: var(--expandable-content-divider-color);
}

.ProductCompareItem {
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: relative;
    height: 100%;
    min-height: 100px;
    border: 1px solid #e0e0e0;
    padding-block-end: 20px;

    &-Details {
        display: flex;
        flex-direction: column;
        margin-block-end: 10px;
        align-items: center;
        row-gap: 15px;
    }

    &-Figure {
        // flex: 0 0 auto;
        // width: 100px;
        // height: 100px;
        padding-block: 10px;
        background: #f1f1f19c;
        padding: 10px;
        width: 100%;
        text-align: center;
    }

    &-ImageLink {
        height: 100%;

        .Image {
            height: 100%;
            padding-block-end: 60%;
            object-fit: contain;

            img {
                object-fit: contain;
                height: 100%;
            }
        }
    }

    &-Actions {
        display: flex;
        align-items: center;
    }

    &-name-section {
        min-height: 38px;
        max-height: 38px;
        height: 100%;
        -webkit-margin-after: 0;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .ProductWishlistButton {
        display: flex;
        inset-block-start: 48px;
        inset-inline-end: 6px;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 35px;
        margin: auto;
        width: 35px;
        border-radius: 50%;
        padding: 2px;
        // background: white;

        .HeartIcon {
            height: 16px;
            min-width: 12px;
            inset-block-start: 37px;
        }
    }

    &-Title {
        flex: 1;
        display: block;
        width: 100%;
        padding-inline-end: 19px;
        margin: 0;
        margin-block-end: 10px;
        font-size: 14px;
        line-height: 1.4;
        color: var(--body-content-color);
        font-weight: 400;
        min-height: 39px;
        height: 110px;
    }

    &-AddToCartBtnWrapper {
        display: block;
        margin: 0;
        width: 100%;
    }

    &-AddToCartBtn {
        flex: 0 0 auto;
        width: 100%;

        &:hover {
            svg {
                fill: #fff;
            }
        }
    }

    &-CloseBtn {
        width: 35px;
        height: 35px;
        position: absolute;
        inset-block-start: 6px;
        inset-inline-end: 6px;
        background: red;
        border-radius: 51%;
        background-color: #fff;
        border: 1px solid #ededed;
        padding: 5px 2px;
    }
}
