/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductLinks {
    .swiper {
        &-button-next, &-button-prev {
            width: 40px;
            height: 40px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border: 0;
            border-radius: 20px;
            transition: unset;
            transform: unset;
        }

        &-button-next::before {
            content: ' \02C3';
            width: 40px;
            height: 40px;
            padding: 7px;
            color: #707070;
            position: absolute;
            inset-block-start: 14%;
        }

        &-button-prev::after {
            content: ' \02C2';
            width: 40px;
            height: 40px;
            padding: 8px;
            color: #707070;
            position: absolute;
            inset-block-start: 7px;
        }
    }

    &-Title {
        font-size: 30px;
        text-align: center;
    }
}
