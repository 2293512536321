/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.QuickOrderProductListCsv {
    &-Wrappers {
        padding: 20px 0;

        @include mobile {
            padding: 0;
            background: #fff;
        }
    }

    &-ProductListTableHead {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 1em;

        &.QuickOrderCsvProductList {
            .ProductImgAndDetails {
                flex-wrap: wrap;
                gap: 0;

                .QuickOrderSearch-productName {
                    flex-basis: 55%;
                    width: 55%;
                }

                .ProductActions-AttributesWrapper {
                    width: 100%;
                    flex-basis: 100%;

                    .QuickOrderCsvSelectedProductsComponent-Attributes {
                        .ProductConfigurableAttributeDropdown {
                            width: 100%;
                        }

                        .ProductConfigurableAttributes-SwatchList {
                            flex-wrap: nowrap;
                            overflow-y: hidden;
                            overflow-x: auto;
                            padding-inline-start: 0;

                            &::-webkit-scrollbar {
                                width: 10px; /* for vertical scrollbars */
                                height: 5px; /* for horizontal scrollbars */
                            }
            
                            &::-webkit-scrollbar-track {
                                background: rgba(0, 0, 0, 0.2);
                                border-radius: 10px;
                            }
            
                            &::-webkit-scrollbar-thumb {
                                background: rgba(0, 0, 0, 0.1);
                            }

                            .ProductAttributeValue {
                                &-String {
                                    white-space: nowrap;
                                }

                                &_qty {
                                    display: none;
                                }

                                @include mobile {
                                    &-qty {
                                        display: none;
                                    }

                                    &-qty_isSelected {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }


                    .ProductConfigurableAttributes {
                        padding: 0;

                        &-SwatchList {
                            .ProductAttributeValue {
                                &-qty {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        thead {
            tr {
                justify-content: center;
                align-items: center;
                font-size: 14px;
                background-color: #fff;
                border-bottom: 1px solid #707070;

                th {
                    background: none;
                    border-radius: 0;
                    text-align: center;

                    &:first-child {
                        width: 42%;
                        text-align: start;

                        @include mobile {
                            width: unset;
                        }
                    }

                    &:last-child {
                        width: 10%;

                        @include mobile {
                            width: unset;
                        }
                    }
                }
            }
        }

        tbody tr:last-child {
            border-bottom: var(--table-body-border);
        }
    }

    &-NoItems {
        p {
            text-align: center;
            margin-block-start: 20px;
        }
    }

    &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--primary-base-color);

        @include mobile {
            display: block;
            padding-block-end: 2rem;
            margin-block-end: 2rem;
        }

        h2 {
            margin-block-end: 15px;
            font-size: 20px;

            @include mobile {
                margin-block: 0px;
                font-size: 16px;
            }
        }

        .clearAll {
            color: var(--primary-base-color);
            cursor: pointer;
            border-bottom: 1px solid var(--primary-base-color);
        }
    }

    &-ProductListTable {
        background: #fff;

        @include mobile {
            padding: 0;
            margin-block-start: 30px;
        }
    }

    &-QuickOrderButton {
        display: flex;
        align-items: center;
        column-gap: 50px;
    }

    &-ProductDataList {
        outline: var(--table-body-border);

        @include mobile {
            padding-block-end: 15px;
            margin-block-start: 15px;
            padding: 10px;

            &:first-child {
                margin-block-start: 0;
            }
        }

        .ProductImgAndDetails {
            display: flex;
            align-items: center;
            column-gap: 20px;

            .ProductImgAndName {
                display: flex;
                align-items: center;
                column-gap: 2rem;
            }

            @include mobile {
                min-width: 50px;
                row-gap: 12px;
                margin-block-end: 10px;
                flex-direction: column;
                align-items: flex-start;
            }

            .ImgDiv {
                .QuickOrder-Picture {
                    width: 104px;
                    padding-block-end: 104px;
                }

                @include mobile {
                    width: initial;
                }
            }

            .QuickOrderSearch-productName {
                @include mobile {
                    width: 100%;
                }
                
                p {
                    margin-block-end: 10px;
                    font-size: 14px;
                    text-transform: capitalize;
                    font-weight: bold;
                }

                p:nth-child(2) {
                    color: #707070;
                    text-transform: none;
                }

                p:last-child {
                    color: #707070;
                }
            }

            .ProductConfigurableAttributes-SwatchList {
                .ProductAttributeValue {
                    &-String {
                        .outofstock-section {
                            .Outofstock-label {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .ProductDetailsStatus {
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: 15px;

            @include mobile {
                column-gap: 10px;
            }
        }

        .ProductQuantity {
            vertical-align: middle;
            text-align: center;

            @include mobile {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 6px;
            }

            span {
                font-size: 14px;
                font-weight: bold;
                margin-inline-start: 20px;
            }
        }

        .ProductTotalPrice {
            vertical-align: middle;
            text-align: center;

            @include mobile {
                display: flex;
                align-items: center;
                gap: 6px;

                label {
                    margin-block-start: 1px;
                }
            }

            span {
                font-size: 14px;
                font-weight: bold;

                @include mobile {
                    margin-block-start: 0;
                }
            }
        }

        .ProductStockStatus {
            vertical-align: middle;
            text-align: center;

            @include mobile {
                display: flex;
                align-items: center;
                gap: 6px;
            }

            span {
                font-size: 14px;

                &.inStock {
                    color: #00741B;
                }

                &.outOfStock {
                    color: var(--primary-base-color);
                }
            }

            p {
                margin-block: 10px;
            }
        }

        .ProductActions {
            vertical-align: middle;
            text-align: center;

            @include mobile {
                text-align: end;
            }

            .ProductActionsBlock {
                display: flex;  
                flex-direction: column;
                align-items: flex-start;
                gap: 18px;
    
                .editButton {
                    border-block-end: 1px solid #000;
                    cursor: pointer;
                }
            }

            .removeButton {
                color: var(--primary-base-color);
                border-block-end: 1px solid var(--primary-base-color);
                cursor: pointer;
            }
        }

        .ProductAttributeValue {
            @include mobile {
                &-qty {
                    display: none;
                }
            }
        }

        &.Exceeded {
            outline: 1px solid var(--primary-base-color);
            border-block-end: none;

            .QuickOrderSearch-productName {
                &::after {
                    content: 'The requested qty is greater than the available qty';
                    color: var(--primary-base-color);
                    font-size: 13px;
                    font-weight: 600;
                }
            }

            @include mobile {
                .ProductDetailsStatus {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    justify-content: space-between;

                    .ProductStockStatus {
                        flex: 1 1 100%;

                        &> div {
                            display: flex;
                            flex-basis: 100%;
                            justify-content: space-between;

                            .inStock,
                            .outOfStock {
                                font-size: 13px;
                            }

                            p {
                                font-size: 13px;
                                margin: 0;
                            }
                        }
                    }

                    .ProductActions {
                        width: 100%;
                    }
                }
            }
        }
    }

    &-QuoteAndCartBtns {
        display: flex;
        justify-content: flex-end;
        gap: 20px;

        @include mobile {
            gap: 10px;
            margin-block-start: 15px;
        }

        button {
            cursor: pointer;
        }

        .QuoteAndCartBtns-QuoteButton {
            width: 188px;
            background-color: #BB8376;
            border-color: #BB8376;
            color: #fff;
            border-radius: 4px;
            height: 40px;

            @include mobile {
                width: 100%;
            }
        }

        .QuoteAndCartBtns-AddToBasketButton {
            width: 188px;
            background-color: #fff;
            color: var(--primary-base-color);
            border-radius: 4px;
            border: 1px solid var(--primary-base-color);
            height: 40px;

            &:hover {
                background-color: var(--primary-base-color);
                color: #fff;
            }
        }
    }
}
