/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductConfigurableAttributes {
    font-size: 14px;
    line-height: 20px;

    @include desktop {
        padding-block-start: 24px;
        padding-block-end: 45px;
    }

    &-SwatchList,
    &-DropDownList {
        text-align: center;
        margin-block-start: 2px;
        margin-block-end: 16px;
    }

    &-Title {
        font-weight: unset;
        margin-block-end: 12px; 
        margin-block-start: 16px;
         
        p {
            margin-block-end: 0px;
        }

        &_isUnselected {
            animation: var(--shake-animation);
        }
    }

    &-SelectedOptionLabel {
        font-weight: normal;
        opacity: 1;
        color: var(--primary-base-color);
    }

    &-SwatchList {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        padding-inline-start: 3px;
        padding-block-start: 3px;
        cursor: pointer;

        &_isUnselected {
            animation: var(--shake-animation);
        }
    }

    &-Placeholder {
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        height: var(--option-size);
        width: var(--option-size);
        margin-block-end: var(--option-margin-block);
        margin-block-start: var(--option-margin-block);
        margin-inline-end: var(--option-margin-inline);
        margin-inline-start: var(--option-margin-inline);
    } 

    &-Expandable:first-of-type {
        border-block-start: none;
    }

    &-Expandable.ExpandableContent:last-of-type {
        border-block-end: none;
    }
}
