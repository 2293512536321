/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.QuickOrderProductList {
    &-Wrappers {
        padding: 20px 0;

        @include mobile {
            padding: 0;
            background: #fff;
            margin-block: 20px;
        }
    }

    &-ProductListTableHead {
        width: 100%;

        // &.QuickOrderCsvProductList {
        //     .ProductImgAndDetails {
        //         flex-wrap: wrap;
        //         gap: 0;

        //         .ImgDiv {
        //             flex-basis: 40%;
        //         }

        //         .QuickOrderSearch-productName {
        //             flex-basis: 55%;
        //             width: 55%;
        //         }

        //         .ProductActions-AttributesWrapper {
        //             width: 100%;
        //             flex-basis: 100%;

        //             .QuickOrderCsvSelectedProductsComponent-Attributes {
        //                 .ProductConfigurableAttributes-Title {
        //                     display: none;
        //                 }

        //                 .ProductConfigurableAttributes-SwatchList {
        //                     flex-wrap: nowrap;
        //                     overflow-y: hidden;
        //                     overflow-x: auto;

        //                     &::-webkit-scrollbar {
        //                         width: 10px; /* for vertical scrollbars */
        //                         height: 5px; /* for horizontal scrollbars */
        //                     }
            
        //                     &::-webkit-scrollbar-track {
        //                         background: rgba(0, 0, 0, 0.2);
        //                         border-radius: 10px;
        //                     }
            
        //                     &::-webkit-scrollbar-thumb {
        //                         background: rgba(0, 0, 0, 0.1);
        //                     }

        //                     .ProductAttributeValue {
        //                         &-String {
        //                             white-space: nowrap;
        //                         }

        //                         &_qty {
        //                             display: none;
        //                         }
        //                     }
        //                 }
        //             }


        //             .ProductConfigurableAttributes {
        //                 padding: 0;

        //                 &-SwatchList {
        //                     .ProductAttributeValue {
        //                         &-qty {
        //                             display: none;
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }

        thead {
            tr {
                justify-content: center;
                align-items: center;
                font-size: 14px;
                background-color: #fff;
                border-bottom: 1px solid #707070;

                th {
                    background: none;
                    border-radius: 0;

                    &:first-child {
                        width: 38%;

                        @include mobile {
                            width: unset;
                        }
                    }
                }
            }
        }

        tbody tr:last-child {
            border-bottom: var(--table-body-border);
        }
    }

    &-NoItems {
        p {
            text-align: center;
            margin-block-start: 20px;
        }
    }

    &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--primary-base-color);

        @include mobile {
            display: block;
            padding-block-end: 2rem;
            margin-block-end: 2rem;
        }

        h3 {
            margin-block-end: 15px;
            font-size: 20px;

            @include mobile {
                margin-block: 0px;
                font-size: 16px;
            }
        }

        .clearAll {
            color: var(--primary-base-color);
            cursor: pointer;
            border-bottom: 1px solid var(--primary-base-color);
        }
    }

    &-ProductListTable {
        background: #fff;
        margin-block-start: 0;
        padding-block-end: 0;
    }

    &-QuickOrderButton {
        display: flex;
        align-items: center;
        column-gap: 50px;

        @include mobile {
            column-gap: 0;
            justify-content: space-between;
        }
    }

    &-ProductDataList {
        @include mobile {
            padding-block-end: 15px;
            border-bottom: 1px solid #9d9d9d;
            margin-block-start: 15px;

            &:first-child {
                margin-block-start: 0;
            }
        }

        .ProductImgAndDetails {
            display: flex;
            align-items: center;
            column-gap: 20px;

            @include mobile {
                column-gap: 12px;
                margin-block-end: 10px;
            }

            .ImgDiv {
                .QuickOrder-Picture {
                    width: 104px;
                    padding-block-end: 104px;
                }

                @include mobile {
                    width: auto;
                }
            }

            .QuickOrderSearch-productName {
                p {
                    margin-block-end: 10px;
                    font-size: 14px;
                    text-transform: capitalize;
                    font-weight: bold;
                }

                p:nth-child(2) {
                    color: #707070;
                    text-transform: none;
                }

                p:last-child {
                    color: var(--primary-base-color);
                }
            }
        }

        .ProductDetailsStatus {
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: 15px;
        }

        .ProductQuantity {
            vertical-align: middle;

            @include mobile {
                display: flex;
                align-items: center;
                gap: 6px;
            }

            span {
                font-size: 14px;
                font-weight: bold;
                margin-inline-start: 20px;
            }
        }

        .ProductTotalPrice {
            vertical-align: middle;

            @include mobile {
                display: flex;
                align-items: center;
                gap: 6px;
            }

            span {
                font-size: 14px;
                font-weight: bold;
            }
        }

        .ProductStockStatus {
            vertical-align: middle;

            @include mobile {
                display: flex;
                align-items: center;
                gap: 6px;
            }

            span {
                font-size: 14px;

                &.inStock {
                    color: #00741B;
                }

                &.outOfStock {
                    color: var(--primary-base-color);
                }
            }
        }

        .ProductActions {
            vertical-align: middle;

            .ProductActionsBlock {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 18px;

                @include  mobile {
                    align-items: flex-end;
                    padding-block-start: 1rem;
                }

                .editButton {
                    border-block-end: 1px solid #000;
                    cursor: pointer;
                }

                .removeButton {
                    color: var(--primary-base-color);
                    border-block-end: 1px solid var(--primary-base-color);
                    cursor: pointer;
                }
            }
        }
    }

    &-QuoteAndCartBtns {
        display: flex;
        justify-content: flex-end;
        gap: 20px;

        @include mobile {
            gap: 10px;
            margin-block-start: 15px;
        }

        button {
            cursor: pointer;
        }

        .QuoteAndCartBtns-QuoteButton {
            width: 188px;
            background-color: #BB8376;
            border-color: #BB8376;
            color: #fff;
            border-radius: 4px;
            height: 40px;

            @include mobile {
                width: 100%;
            }
        }

        .QuoteAndCartBtns-AddToBasketButton {
            width: 188px;
            background-color: #fff;
            color: var(--primary-base-color);
            border-radius: 4px;
            border: 1px solid var(--primary-base-color);
            height: 40px;

            &:hover {
                background-color: var(--primary-base-color);
                color: #fff;
            }
        }
    }
}
