/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductPage {
    @include mobile() {
        margin: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }

    .ExpandableContent {
        padding: 24px 0px;

        @include mobile() {
            padding: 0px;
        }

        &-Content {
            @include desktop {
                max-height: 0;
                opacity: 0;
            }

            &_isContentExpanded {
                @include desktop {
                    max-height: 100%;
                    opacity: 1;
                }
            }
        }
    }

    .ProductActions {
        &-BrandName {
            font-size: 16px;

            @include mobile() {
                font-size: 12px;
            }
        }

        &-AttributesWrapper {
            border-bottom: 1px solid #f1f1f1;
        }

        &-LoginLink {
            margin-block-start: 28px;
            padding-block-end: 14px;
            border-bottom: 1px solid #cbcbcb79;
        }

        &-PriceWrapper {
            @include desktop {
                display: flex;
                flex-direction: column;
                row-gap: 12px;
                align-items: flex-start;
            }
        }

        &-Price {
            padding-inline-end: 36px;
        }

        &-Qty {
            .Field {
                input {
                    @include mobile() {
                        height: 20px;
                    }
                }
            }

            @include mobile() {
                width: 100px;
                height: 40px;
            }
        }

        &-Title {
            @include mobile {
                font-weight: bold;
                font-size: 14px;
                margin-block-start: 12px;
                line-height: 20px;
                margin-block-end: 12px;
            }
        }

        &-Section_type_sku {
            display: block;
            margin-block-end: 12px;
            
            @include mobile() {
                margin-block-end: 0px;
            }
        }

        .AddToCart {
            background-color: #fff;
            border: 1px solid #f6331c;
            text-transform: inherit;

            @include mobile() {
                min-width: calc(100% - 115px);
                width: 100%;
                height: 40px;
                background-color: #f6331c;
                border: none;
            }

            span {
                @include mobile() {
                    color: white;
                }
            }

            &:hover {
                background-color: #f6331c;
                color: white;
                border: 1px solid #f6331c;
            }
        }
    }

    .ProductPrice {
        &-PriceBadge {
            display: none;
        }

        &-PriceValue {
            @include mobile() {
                font-size: 14px;
                margin-block-start: 0px;
            }
        }
    }

    .ProductCard {
        &-Picture {
            img {
                object-fit: cover;
            }
        }
    }

    .ProductConfigurableAttributes {
        &-SwatchList {
            column-gap: 10px;
            row-gap: 10px;

            @include mobile() {
                flex-wrap: unset;
                overflow: scroll;
            }
        }
    }

    .String_main {
        position: relative;

        @include mobile() {
            position: unset;
        }

        .ProductAttributeValue-qty {
            display: none;

            &_isSelected {
                display: block;
                border: 1px solid var(--primary-base-color);
                margin-inline-end: 10px;
                margin-block-start: 12px;
                color: var(--primary-base-color);
            }
        }
    }

    .String_main:hover,
    :focus {
        .ProductAttributeValue_qty {
            display: inline;
            cursor: pointer;
        }
    }

    .ProductAttributeValue {
        &-String {
            height: 56px;
            border: 1px solid #000000;
            width: 115px;

            @include mobile {
                width: 99px;
            }

            span {
                color: #000000;
            }

            &:hover {
                border: 1px solid var(--primary-base-color);

                span {
                    color: #000000;
                }
            }

            &_isSelected {
                border: 1px solid red;
                font-weight: bold;
            }
        }
       

        &_isNotAvailable {
            cursor: pointer;
            pointer-events: visible;

            .ProductAttributeValue-Color {
                &::before {
                    @include not-available;

                    background-color: var(--option-check-mark-background);
                }
            }

            .ProductAttributeValue-String {
                &_isSelected {
                    font-weight: 500;
                    border: 1px solid var(--primary-base-color);
                }
                
                border-style: dashed;
                border-color: #000000;
                margin-inline-end: unset;

                @include mobile {
                    padding: unset;
                }

                .outofstock-section_main {
                    display: flex;

                    .outofstock-section {
                        display: flex;
                        flex-direction: column;

                        .Outofstock-value {
                            font-size: 12px;
                            opacity: 0.5;
                            line-height: normal;
                        }

                        .Outofstock-label {
                            color: #000000;
                            opacity: 1;
                            font-weight: bold;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }

    .ProductInformation {
        &-Description {
            margin-block-start: 21px;
            font-size: 14px;
            line-height: 21px;

            @include mobile {
                margin-block-end: 21px;
                margin-block-start: 0;
            }

            div {
                p {
                    color: #707070;
                }
            }
        }
    }

    @include tablet {
        padding-block-start: 0;
        margin-block-start: 0;
    }

    @include mobile {
        padding-block-end: 133px;
        margin-inline: 16px;
        margin-block-start: 0;
    }

    &-paymenet-description {
        padding: 0px 50px;
        margin-block-start: 50px;

        @include mobile() {
            padding: 0px;
            display: none;
        }
    }

    &-description-content {
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;

        @include mobile() {
            border-top: none;
            border-bottom: none;
            display: none;
        }
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 36px;
        grid-template-rows: min-content min-content;
        padding-block-start: 24px;

        @include desktop {
            grid-template-areas: ". actions";
            padding-block-end: 72px;

            .ProductActions {
                grid-area: actions;
            }
        }

        @include mobile {
            grid-template-columns: 100%;
            padding: 0;
        }

        > .RelatedProducts {
            display: none;

            @include desktop {
                display: block;
            }
        }
    }
}
