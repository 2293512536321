/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Productimageoverlayactions {
    &-main {
        position: absolute;
        inset-block-start: 25px;
        inset-inline-end: 25px;
        z-index: 10;

        @include mobile() {
            inset-block-start: 20px;
            inset-inline-end: 20px;
        }
    }

    &-icons {
        display: flex;
        flex-direction: column;
        row-gap: 30px;

        @include mobile() {
            row-gap: 15px;
        }
    }

    &-icons_background {
        background-color: #FFFFFF;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mobile() {
            width: 30px;
            height: 30px;
        }

        svg {
            width: 24px;
            height: 24px;

            @include mobile() {
                width: 20px;
                height: 15px;
            }

            &:hover {
                fill: var(--primary-base-color);

                path {
                    fill: var(--primary-base-color);
                    stroke: none;
                }
            }
        }

        .ProductCompareButton {
            &-Button {
                .CompareIcon {
                    fill: #000;

                    path {
                        fill: #000;
                    }

                    &_isActive {
                        path {
                            fill: var(--primary-base-color);
                        }
                    }

                    &:hover {
                        fill: var(--primary-base-color);

                        path {
                            fill: var(--primary-base-color);
                        }
                    }
                }
            }
        }
    }
}
