/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// util class, is applied on <html> on fullscreen image

.overscrollPrevented {
    overscroll-behavior: contain;
}

.ProductPage {
    .ProductGallery {
        display: flex;
        flex-direction: row-reverse;
        column-gap: 20px;
        height: 571px;
        margin-inline: 0;

        @include narrow-desktop {
            min-width: unset;
        }

        @include mobile {
            height: 300px;
            margin-block-end: 16px;
            margin: 0%;
        }

        &-BrandLogo {
            display: none;
            position: absolute;
            inset-block-end: 20px;
            z-index: 4;
            border-radius: 50%;
            background-color: #fff;
            padding: 9px;
            width: 75px;
            height: 75px;
            transition: inset-block-end 0.5s;
            inset-inline-end: 20px;

            @include mobile() {
                inset-block-end: 14px;
                inset-inline-end: 14px;
                width: 48px;
                height: 48px;
            }

            &.ProductGallery-BrandLogo_layout_grid {
                img {
                    width: 100%;
                }
            }
        }

        &-Additional {
            flex: 0 0 auto;
            height: 571px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            @include mobile {
                display: none;
            }

            @media screen and (max-width: 1025px) {
                display: none;
            }

            &_isImageZoomPopupActive {
                position: absolute;
                inset-block-end: 20px;
                inset-inline-start: 20px;

                .CarouselScrollItem {
                    filter: opacity(0.75);

                    &_isActive {
                        border-width: 2px;
                        filter: opacity(1);
                    }
                }
            }

            &_isWithEmptySwitcher {
                height: 66px;
            }
        }

        &-Additional-image-overlay {
            position: relative;
        }

        &-SliderWrapper {
            flex: 1;
            height: 100%;

            @include mobile() {
                width: unset;
            }
        }

        &-Slider {
            height: 100%;
            opacity: var(--sliderOpacity);

            &_isImageZoomPopupActive {
                cursor: pointer;
            }

            &_isZoomInCursor {
                cursor: default;
            }
        }

        .react-transform-component,
        .react-transform-element,
        &-SliderImage {
            height: 100%;
            width: 100%;
            background: #f8f8f8;

            img {
                width: 100%;
            }
        }
    }
}

.ImageZoomPopup {
    .ProductGallery {
        &-BrandLogo {
            position: absolute;
            inset-block-end: 20px;
            z-index: 4;
            border-radius: 50%;
            background-color: #fff;
            padding: 9px;
            width: 75px;
            height: 75px;
            transition: inset-block-end 0.5s;
            inset-inline-end: 20px;

            @include mobile() {
                inset-block-end: 14px;
                inset-inline-end: 14px;
                width: 48px;
                height: 48px;
            }
        }
    }

    .Productimageoverlayactions {
        &-main {
            display: none;
        }
    }
}
