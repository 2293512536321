/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductCompareAttributeRow {
    display: flex;
    font-size: 14px;
    column-gap: 10px;

    @include mobile {
        display: flex;
    }

    &-Title {
        flex: 0 0 auto;
        width: var(--product-compare-sidebar-width);
        position: sticky;
        inset-inline-start: 0;
        font-weight: 700;
        font-size: 14px;
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        display: flex;
        align-items: center;

        @include mobile {
            padding-inline: 15px;
            padding-block-end: 10px;
            line-height: 22px;
            z-index: 99;
            background-color: $white;
            width: 131px;
        }

        @include desktop {
            background-color: $white;
            z-index: 10;
        }
    }

    &-Values {
        display: flex;
        column-gap: 10px;

        .ProductPrice {
            .ProductPrice-Price {
                .ProductPrice-PriceValue {
                    font-size: 16px;
                }
            }
        }
    }

    // make attribute value cell content narrower than table header

    &-Value {
        @include default-list;

        border-right: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        max-width: 230px;
        width: 100%;
        padding-inline: 10px;
        padding-block: 20px;
        border-bottom: 1px solid #e0e0e0;        
        flex: 0 0 auto;
        // padding-block: 0;
        // padding-inline: var(--product-compare-item-gap) calc(var(--product-compare-item-gap) + var(--prouduct-compare-additional-gap));
        // width: var(--product-compare-column-width);
        text-align: justify;
        word-break: break-all;

        img {
            height: auto;
        }

        @include mobile {
            width: 230px;
        }
    }

    &-OutOfStock {
        /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
        @extend .ProductCompareAttributeRow-Value;

        padding-block-start: 15px;

        @include mobile {
            padding-block-start: 39px;
        }
    }
}
