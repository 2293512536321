/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductListWidget {
    @include desktop {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 69px 0 20px 0;
    }

    @include mobile {
        margin: 33px 0 0 0;
    }

    h2 {
        @include desktop {
            font-size: 30px;
            margin-block-end: 56px;
        }

        @include mobile {
            font-size: 20px;
            margin-block-end: 24px;
        }
    }
    
    .product-slider {
        @include desktop {
            max-width: 1440px;
            width: 100%;
            padding: 0;
        }

        @include mobile {
            padding: 0;
        }

        .slick-slider.Codilar.slick-initialized {
            @include desktop {
                display: flex;
            }
        }

        .slick-arrow.slick-prev {
            width: 40px;
            height: 40px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            position: absolute;
            inset-inline-start: 3%;
            z-index: 1;
            border-radius: 22px;

            svg {
                transform: rotate(180deg);
            }
        }

        .slick-arrow {
            @include mobile {
                visibility: hidden;
            }
        }

        .slick-arrow svg {
            width: 20px;
            margin: 2px 0 0 10px;
        }

        .slick-arrow.slick-next {
            width: 40px;
            height: 40px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            position: absolute;
            inset-inline-end: 3%;
            z-index: 1;
            border-radius: 22px;
        }
    }
}
